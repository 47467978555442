define("discourse/plugins/discourse-adplugin/discourse/components/freestar-ad", ["exports", "@ember/component", "@ember/object/computed", "@ember/template", "rsvp", "discourse/lib/load-script", "discourse/lib/preload-store", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "@ember/template-factory"], function (_exports, _component, _computed, _template, _rsvp, _loadScript, _preloadStore, _environment, _decorators, _adComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAd}}
    {{#if this.site.mobileView}}
      <div class="freestar-ad-label" style={{this.adTitleStyleMobile}}><h2>{{i18n
            "adplugin.advertisement_label"
          }}</h2></div>
      <div
        id={{this.divId}}
        style={{this.adWrapperStyle}}
        class="freestar-ad-unit"
        align="center"
      ></div>
    {{else}}
      <div class="freestar-ad-label"><h2>{{i18n
            "adplugin.advertisement_label"
          }}</h2></div>
      <div
        id={{this.divId}}
        style={{this.adWrapperStyle}}
        class="freestar-ad-unit"
        align="center"
      ></div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "tWa4k3px",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"    \"],[10,0],[14,0,\"freestar-ad-label\"],[15,5,[30,0,[\"adTitleStyleMobile\"]]],[12],[10,\"h2\"],[12],[1,[28,[35,3],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[15,1,[30,0,[\"divId\"]]],[15,5,[30,0,[\"adWrapperStyle\"]]],[14,0,\"freestar-ad-unit\"],[14,\"align\",\"center\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"freestar-ad-label\"],[12],[10,\"h2\"],[12],[1,[28,[35,3],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[15,1,[30,0,[\"divId\"]]],[15,5,[30,0,[\"adWrapperStyle\"]]],[14,0,\"freestar-ad-unit\"],[14,\"align\",\"center\"],[12],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"div\",\"h2\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/freestar-ad.hbs",
    "isStrictMode": false
  });
  let _loaded = false,
    _promise = null,
    ads = {},
    nextSlotNum = 1,
    renderCounts = {};
  function env() {
    const bpSettings = _preloadStore.default.get("bpSettings");
    return bpSettings ? bpSettings.env : "production";
  }
  function getNextSlotNum() {
    return nextSlotNum++;
  }

  // This creates an array for the values of the custom targeting key
  function valueParse(value) {
    let final = value.replace(/ /g, "");
    final = final.replace(/['"]+/g, "");
    final = final.split(",");
    return final;
  }

  // This creates an array for the key of the custom targeting key
  function keyParse(word) {
    let key = word;
    key = key.replace(/['"]+/g, "");
    key = key.split("\n");
    return key;
  }

  // This should call adslot.setTargeting(key for that location, value for that location)
  function custom_targeting(key_array, value_array, targeting) {
    for (let i = 0; i < key_array.length; i++) {
      if (key_array[i]) {
        targeting[key_array[i]] = valueParse(value_Array[i]);
      }
    }
  }
  const DESKTOP_SETTINGS = {
    "topic-list-top": {
      code: "freestar_topic_list_top_code",
      sizes: "freestar_topic_list_top_ad_sizes",
      targeting_keys: "freestar_target_topic_list_top_key_code",
      targeting_values: "freestar_target_topic_list_top_value_code"
    },
    "topic-list-between": {
      code: "freestar_topic_list_between_code",
      sizes: "freestar_topic_list_between_ad_sizes",
      targeting_keys: "freestar_target_topic_list_between_key_code",
      targeting_values: "freestar_target_topic_list_between_value_code"
    },
    "topic-above-post-stream": {
      code: "freestar_topic_above_post_stream_code",
      sizes: "freestar_topic_above_post_stream_ad_sizes",
      targeting_keys: "freestar_target_topic_above_post_stream_key_code",
      targeting_values: "freestar_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "freestar_topic_above_suggested_code",
      sizes: "freestar_topic_above_suggested_ad_sizes",
      targeting_keys: "freestar_target_topic_above_suggested_key_code",
      targeting_values: "freestar_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "freestar_post_bottom_code",
      sizes: "freestar_post_bottom_ad_sizes",
      targeting_keys: "freestar_target_post_bottom_key_code",
      targeting_values: "freestar_target_post_bottom_value_code"
    }
  };
  const MOBILE_SETTINGS = {
    "topic-list-top": {
      code: "freestar_mobile_topic_list_top_code",
      sizes: "freestar_mobile_topic_list_top_ad_sizes",
      targeting_keys: "freestar_target_topic_list_top_key_code",
      targeting_values: "freestar_target_topic_list_top_value_code"
    },
    "topic-list-between": {
      code: "freestar_mobile_topic_list_between_code",
      sizes: "freestar_mobile_topic_list_between_ad_sizes",
      targeting_keys: "freestar_target_topic_list_between_key_code",
      targeting_values: "freestar_target_topic_list_between_value_code"
    },
    "topic-above-post-stream": {
      code: "freestar_mobile_topic_above_post_stream_code",
      sizes: "freestar_mobile_topic_above_post_stream_ad_sizes",
      targeting_keys: "freestar_target_topic_above_post_stream_key_code",
      targeting_values: "freestar_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "freestar_mobile_topic_above_suggested_code",
      sizes: "freestar_mobile_topic_above_suggested_ad_sizes",
      targeting_keys: "freestar_target_topic_above_suggested_key_code",
      targeting_values: "freestar_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "freestar_mobile_post_bottom_code",
      sizes: "freestar_mobile_post_bottom_ad_sizes",
      targeting_keys: "freestar_target_post_bottom_key_code",
      targeting_values: "freestar_target_post_bottom_value_code"
    }
  };
  function getWidthAndHeight(placement, settings, isMobile) {
    let config, size;
    if (isMobile) {
      config = MOBILE_SETTINGS[placement];
    } else {
      config = DESKTOP_SETTINGS[placement];
    }
    if (!renderCounts[placement]) {
      renderCounts[placement] = 0;
    }
    const sizes = (settings[config.sizes] || "").split("|");
    if (sizes.length >= 1) {
      // more than one size means nothing to freestar
      size = sizes[0];
    }
    const split = size.split('x');
    const sizeObj = {
      width: parseInt(split[0], 10),
      height: parseInt(split[1], 10)
    };
    if (!isNaN(sizeObj.width) && !isNaN(sizeObj.height)) {
      return sizeObj;
    }
  }
  function defineSlot(divId, placement, settings, isMobile, width, height, categoryTarget) {
    if (!settings.freestar_publisher_id) {
      return;
    }
    if (ads[divId]) {
      return ads[divId];
    }
    const publisherId = settings.freestar_publisher_id;
    let config;
    if (isMobile) {
      config = MOBILE_SETTINGS[placement];
    } else {
      config = DESKTOP_SETTINGS[placement];
    }
    const targeting = {};
    custom_targeting(keyParse(settings[config.targeting_keys]), keyParse(settings[config.targeting_values]), targeting);
    if (categoryTarget) {
      targeting["discourse-category"] = categoryTarget;
    }
    const adDefinition = {
      slotId: divId,
      placementName: settings[config.code],
      targeting
    };
    window.freestar.newAdSlots([adDefinition]);
    ads[divId] = {
      placement,
      width,
      height,
      definition: adDefinition
    };
    return ads[divId];
  }
  function destroySlot(divId) {
    if (ads[divId] && window.freestar) {
      window.freestar.deleteAdSlots([divId]);
      delete ads[divId];
    }
  }
  function loadFreestar(publisherId) {
    /**
     * Refer to this article for help:
     * https://help.freestar.com/help/freestar-standard-implementation
     */

    if (_loaded) {
      return _rsvp.default.resolve();
    }
    if (_promise) {
      return _promise;
    }

    // The boilerplate code
    window["freestar"] = window["freestar"] || {};
    window["freestar"].queue = window["freestar"].queue || [];
    if (env() !== "production") {
      window["freestar"].queue.push(function () {
        window["googletag"].pubads().set('page_url', 'https://forums.babypips.com/');
      });
    }
    let freestarSrc = "https://a.pub.network/" + publisherId + "/pubfig.min.js";
    _promise = (0, _loadScript.default)(freestarSrc, {
      scriptTag: true
    }).then(function () {
      _loaded = true;
    });
    return _promise;
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _adComponent.default.extend(dt7948.p({
    classNames: ["freestar-ad"],
    classNameBindings: ["adUnitClass"],
    attributeBindings: ["colspanAttribute:colspan"],
    loadedFreestar: false,
    lastAdRefresh: null,
    width: (0, _computed.alias)("size.width"),
    height: (0, _computed.alias)("size.height"),
    colspanAttribute() {
      return this.tagName === "td" ? "5" : null;
    },
    size() {
      return getWidthAndHeight(this.get("placement"), this.siteSettings, this.site.mobileView);
    },
    publisherId(globalId) {
      return globalId;
    },
    divId(placement, postNumber) {
      let slotNum = getNextSlotNum();
      if (postNumber) {
        return `div-freestar-ad-${slotNum}-${placement}-${postNumber}`;
      } else {
        return `div-freestar-ad-${slotNum}-${placement}`;
      }
    },
    adUnitClass(placement, showAd) {
      return showAd ? `freestar-ad-${placement}` : "";
    },
    adWrapperStyle(w, h) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)(`min-width: ${w}px; min-height: ${h}px;`);
      }
    },
    adTitleStyleMobile(w) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)(`width: ${w}px;`);
      }
    },
    showAd(publisherId, showToTrustLevel, showToGroups, showAfterPost, showOnCurrentPage, size) {
      return publisherId && showToTrustLevel && showToGroups && showAfterPost && showOnCurrentPage
      // && size
      ;
    },
    showFreestarAds() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_freestar_ads;
    },
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.freestar_nth_post_code, 10));
    },
    // 3 second delay between calls to refresh ads in a component.
    // Ember often calls updated() more than once, and *sometimes*
    // updated() is called after _initFreestar().
    shouldRefreshAd() {
      const lastAdRefresh = this.get("lastAdRefresh");
      if (!lastAdRefresh) {
        return true;
      }
      return new Date() - lastAdRefresh > 3000;
    },
    updated() {
      if (!this.shouldRefreshAd()) {
        return;
      }
      let slot = ads[this.get("divId")];
      if (!slot) {
        return;
      }
      let categorySlug = this.get("currentCategorySlug");
      if (this.get("loadedFreestar")) {
        this.set("lastAdRefresh", new Date());
        // TODO: Update targeting ... doesn't seem Freestar supports this
      }
    },
    _initFreestar() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }
      if (!this.get("showAd")) {
        return;
      }
      loadFreestar(this.publisherId).then(() => {
        this.set("loadedFreestar", true);
        this.set("lastAdRefresh", new Date());
        window.freestar.queue.push(() => {
          defineSlot(this.get("divId"), this.get("placement"), this.siteSettings, this.site.mobileView, this.get("width"), this.get("height"), this.get("currentCategorySlug") || "0");
        });
      });
    },
    willRender() {
      this._super(...arguments);
      if (!this.get("showAd")) {
        return;
      }
    },
    cleanup() {
      destroySlot(this.get("divId"));
    }
  }, [["method", "colspanAttribute", [_decorators.default]], ["method", "size", [_decorators.default]], ["method", "publisherId", [(0, _decorators.default)("siteSettings.freestar_publisher_id")]], ["method", "divId", [(0, _decorators.default)("placement", "postNumber")]], ["method", "adUnitClass", [(0, _decorators.default)("placement", "showAd")]], ["method", "adWrapperStyle", [(0, _decorators.default)("width", "height")]], ["method", "adTitleStyleMobile", [(0, _decorators.default)("width")]], ["method", "showAd", [(0, _decorators.default)("publisherId", "showFreestarAds", "showToGroups", "showAfterPost", "showOnCurrentPage", "size")]], ["method", "showFreestarAds", [_decorators.default]], ["method", "showAfterPost", [(0, _decorators.default)("postNumber")]], ["method", "updated", [(0, _decorators.on)("didUpdate")]], ["method", "_initFreestar", [(0, _decorators.on)("didInsertElement")]], ["method", "cleanup", [(0, _decorators.on)("willDestroyElement")]]])));
});
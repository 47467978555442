define("discourse/plugins/discourse-adplugin/discourse/components/google-dfp-ad", ["exports", "@ember/component", "@ember/object/computed", "@ember/template", "@ember-decorators/component", "rsvp", "discourse/lib/load-script", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "@ember/template-factory"], function (_exports, _component, _computed, _template, _component2, _rsvp, _loadScript, _environment, _decorators, _adComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAd}}
    {{#if this.site.mobileView}}
      <div class="google-dfp-ad-label" style={{this.adTitleStyleMobile}}><h2
        >{{i18n "adplugin.advertisement_label"}}</h2></div>
      <div
        id={{this.divId}}
        style={{this.adWrapperStyle}}
        class="dfp-ad-unit"
        align="center"
      ></div>
    {{else}}
      <div class="google-dfp-ad-label"><h2>{{i18n
            "adplugin.advertisement_label"
          }}</h2></div>
      <div
        id={{this.divId}}
        style={{this.adWrapperStyle}}
        class="dfp-ad-unit"
        align="center"
      ></div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "GrVgisGC",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"    \"],[10,0],[14,0,\"google-dfp-ad-label\"],[15,5,[30,0,[\"adTitleStyleMobile\"]]],[12],[10,\"h2\"],[12],[1,[28,[35,3],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[15,1,[30,0,[\"divId\"]]],[15,5,[30,0,[\"adWrapperStyle\"]]],[14,0,\"dfp-ad-unit\"],[14,\"align\",\"center\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"google-dfp-ad-label\"],[12],[10,\"h2\"],[12],[1,[28,[35,3],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[15,1,[30,0,[\"divId\"]]],[15,5,[30,0,[\"adWrapperStyle\"]]],[14,0,\"dfp-ad-unit\"],[14,\"align\",\"center\"],[12],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"div\",\"h2\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/google-dfp-ad.hbs",
    "isStrictMode": false
  });
  let _loaded = false,
    _promise = null,
    ads = {},
    nextSlotNum = 1,
    renderCounts = {};
  function getNextSlotNum() {
    return nextSlotNum++;
  }
  function splitWidthInt(value) {
    let str = value.substring(0, 3);
    return str.trim();
  }
  function splitHeightInt(value) {
    let str = value.substring(4, 7);
    return str.trim();
  }

  // This creates an array for the values of the custom targeting key
  function valueParse(value) {
    let final = value.replace(/ /g, "");
    final = final.replace(/['"]+/g, "");
    final = final.split(",");
    return final;
  }

  // This creates an array for the key of the custom targeting key
  function keyParse(word) {
    let key = word;
    key = key.replace(/['"]+/g, "");
    key = key.split("\n");
    return key;
  }

  // This should call adslot.setTargeting(key for that location, value for that location)
  function custom_targeting(key_array, value_array, adSlot) {
    for (let i = 0; i < key_array.length; i++) {
      if (key_array[i]) {
        adSlot.setTargeting(key_array[i], valueParse(value_array[i]));
      }
    }
  }
  const DESKTOP_SETTINGS = {
    "topic-list-top": {
      code: "dfp_topic_list_top_code",
      sizes: "dfp_topic_list_top_ad_sizes",
      targeting_keys: "dfp_target_topic_list_top_key_code",
      targeting_values: "dfp_target_topic_list_top_value_code"
    },
    "topic-above-post-stream": {
      code: "dfp_topic_above_post_stream_code",
      sizes: "dfp_topic_above_post_stream_ad_sizes",
      targeting_keys: "dfp_target_topic_above_post_stream_key_code",
      targeting_values: "dfp_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "dfp_topic_above_suggested_code",
      sizes: "dfp_topic_above_suggested_ad_sizes",
      targeting_keys: "dfp_target_topic_above_suggested_key_code",
      targeting_values: "dfp_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "dfp_post_bottom_code",
      sizes: "dfp_post_bottom_ad_sizes",
      targeting_keys: "dfp_target_post_bottom_key_code",
      targeting_values: "dfp_target_post_bottom_value_code"
    }
  };
  const MOBILE_SETTINGS = {
    "topic-list-top": {
      code: "dfp_mobile_topic_list_top_code",
      sizes: "dfp_mobile_topic_list_top_ad_sizes",
      targeting_keys: "dfp_target_topic_list_top_key_code",
      targeting_values: "dfp_target_topic_list_top_value_code"
    },
    "topic-above-post-stream": {
      code: "dfp_mobile_topic_above_post_stream_code",
      sizes: "dfp_mobile_topic_above_post_stream_ad_sizes",
      targeting_keys: "dfp_target_topic_above_post_stream_key_code",
      targeting_values: "dfp_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "dfp_mobile_topic_above_suggested_code",
      sizes: "dfp_mobile_topic_above_suggested_ad_sizes",
      targeting_keys: "dfp_target_topic_above_suggested_key_code",
      targeting_values: "dfp_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "dfp_mobile_post_bottom_code",
      sizes: "dfp_mobile_post_bottom_ad_sizes",
      targeting_keys: "dfp_target_post_bottom_key_code",
      targeting_values: "dfp_target_post_bottom_value_code"
    }
  };
  function getWidthAndHeight(placement, settings, isMobile) {
    let config, size;
    if (isMobile) {
      config = MOBILE_SETTINGS[placement];
    } else {
      config = DESKTOP_SETTINGS[placement];
    }
    if (!renderCounts[placement]) {
      renderCounts[placement] = 0;
    }
    const sizes = (settings[config.sizes] || "").split("|");
    if (sizes.length === 1) {
      size = sizes[0];
    } else {
      size = sizes[renderCounts[placement] % sizes.length];
      renderCounts[placement] += 1;
    }
    if (size === "fluid") {
      return {
        width: "fluid",
        height: "fluid"
      };
    }
    const sizeObj = {
      width: parseInt(splitWidthInt(size), 10),
      height: parseInt(splitHeightInt(size), 10)
    };
    if (!isNaN(sizeObj.width) && !isNaN(sizeObj.height)) {
      return sizeObj;
    }
  }
  function defineSlot(divId, placement, settings, isMobile, width, height, categoryTarget) {
    if (!settings.dfp_publisher_id) {
      return;
    }
    if (ads[divId]) {
      return ads[divId];
    }
    let ad, config, publisherId;
    if (isMobile) {
      publisherId = settings.dfp_publisher_id_mobile || settings.dfp_publisher_id;
      config = MOBILE_SETTINGS[placement];
    } else {
      publisherId = settings.dfp_publisher_id;
      config = DESKTOP_SETTINGS[placement];
    }
    ad = window.googletag.defineSlot("/" + publisherId + "/" + settings[config.code], [width, height], divId);
    custom_targeting(keyParse(settings[config.targeting_keys]), keyParse(settings[config.targeting_values]), ad);
    if (categoryTarget) {
      ad.setTargeting("discourse-category", categoryTarget);
    }
    ad.addService(window.googletag.pubads());
    ads[divId] = {
      ad,
      width,
      height
    };
    return ads[divId];
  }
  function destroySlot(divId) {
    if (ads[divId] && window.googletag) {
      window.googletag.destroySlots([ads[divId].ad]);
      delete ads[divId];
    }
  }
  function loadGoogle() {
    /**
     * Refer to this article for help:
     * https://support.google.com/admanager/answer/4578089?hl=en
     */

    if (_loaded) {
      return _rsvp.default.resolve();
    }
    if (_promise) {
      return _promise;
    }

    // The boilerplate code
    let dfpSrc = ("https:" === document.location.protocol ? "https:" : "http:") + "//securepubads.g.doubleclick.net/tag/js/gpt.js";
    _promise = (0, _loadScript.default)(dfpSrc, {
      scriptTag: true
    }).then(function () {
      _loaded = true;
      if (window.googletag === undefined) {
        // eslint-disable-next-line no-console
        console.log("googletag is undefined!");
      }
      window.googletag.cmd.push(function () {
        // Infinite scroll requires SRA:
        window.googletag.pubads().enableSingleRequest();

        // we always use refresh() to fetch the ads:
        window.googletag.pubads().disableInitialLoad();

        // Improve CSP compatibility (https://developers.google.com/publisher-tag/guides/content-security-policy)
        window.googletag.pubads().setForceSafeFrame(true);
        window.googletag.enableServices();
      });
    });
    window.googletag = window.googletag || {
      cmd: []
    };
    return _promise;
  }
  const GoogleDfpAd = dt7948.c(class GoogleDfpAd extends _adComponent.default {
    loadedGoogletag = false;
    lastAdRefresh = null;
    static #_ = (() => dt7948.g(this.prototype, "width", [(0, _computed.alias)("size.width")]))();
    #width = (() => (dt7948.i(this, "width"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "height", [(0, _computed.alias)("size.height")]))();
    #height = (() => (dt7948.i(this, "height"), void 0))();
    size() {
      return getWidthAndHeight(this.get("placement"), this.siteSettings, this.site.mobileView);
    }
    static #_3 = (() => dt7948.n(this.prototype, "size", [_decorators.default]))();
    publisherId(globalId, mobileId, isMobile) {
      if (isMobile) {
        return mobileId || globalId;
      } else {
        return globalId;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "publisherId", [(0, _decorators.default)("siteSettings.dfp_publisher_id", "siteSettings.dfp_publisher_id_mobile", "site.mobileView")]))();
    divId(placement, postNumber) {
      let slotNum = getNextSlotNum();
      if (postNumber) {
        return `div-gpt-ad-${slotNum}-${placement}-${postNumber}`;
      } else {
        return `div-gpt-ad-${slotNum}-${placement}`;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "divId", [(0, _decorators.default)("placement", "postNumber")]))();
    adUnitClass(placement, showAd) {
      return showAd ? `dfp-ad-${placement}` : "";
    }
    static #_6 = (() => dt7948.n(this.prototype, "adUnitClass", [(0, _decorators.default)("placement", "showAd")]))();
    adWrapperStyle(w, h) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)(`min-width: ${w}px; min-height: ${h}px;`);
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "adWrapperStyle", [(0, _decorators.default)("width", "height")]))();
    adTitleStyleMobile(w) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)(`width: ${w}px;`);
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "adTitleStyleMobile", [(0, _decorators.default)("width")]))();
    showAd(publisherId, showDfpAds, showToGroups, showAfterPost, showOnCurrentPage, size) {
      return publisherId && showDfpAds && showToGroups && showAfterPost && showOnCurrentPage && size;
    }
    static #_9 = (() => dt7948.n(this.prototype, "showAd", [(0, _decorators.default)("publisherId", "showDfpAds", "showToGroups", "showAfterPost", "showOnCurrentPage", "size")]))();
    showDfpAds() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_dfp_ads;
    }
    static #_10 = (() => dt7948.n(this.prototype, "showDfpAds", [_decorators.default]))();
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.dfp_nth_post_code, 10));
    }

    // 3 second delay between calls to refresh ads in a component.
    // Ember often calls updated() more than once, and *sometimes*
    // updated() is called after _initGoogleDFP().
    static #_11 = (() => dt7948.n(this.prototype, "showAfterPost", [(0, _decorators.default)("postNumber")]))();
    shouldRefreshAd() {
      const lastAdRefresh = this.get("lastAdRefresh");
      if (!lastAdRefresh) {
        return true;
      }
      return new Date() - lastAdRefresh > 3000;
    }
    updated() {
      if (!this.shouldRefreshAd()) {
        return;
      }
      let slot = ads[this.get("divId")];
      if (!(slot && slot.ad)) {
        return;
      }
      let ad = slot.ad,
        categorySlug = this.get("currentCategorySlug");
      if (this.get("loadedGoogletag")) {
        this.set("lastAdRefresh", new Date());
        window.googletag.cmd.push(() => {
          ad.setTargeting("discourse-category", categorySlug || "0");
          window.googletag.pubads().refresh([ad]);
        });
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "updated", [(0, _decorators.on)("didUpdate")]))();
    _initGoogleDFP() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }
      if (!this.get("showAd")) {
        return;
      }
      loadGoogle().then(() => {
        this.set("loadedGoogletag", true);
        this.set("lastAdRefresh", new Date());
        window.googletag.cmd.push(() => {
          let slot = defineSlot(this.get("divId"), this.get("placement"), this.siteSettings, this.site.mobileView, this.get("width"), this.get("height"), this.get("currentCategorySlug") || "0");
          if (slot && slot.ad) {
            // Display has to be called before refresh
            // and after the slot div is in the page.
            window.googletag.display(this.get("divId"));
            window.googletag.pubads().refresh([slot.ad]);
          }
        });
      });
    }
    static #_13 = (() => dt7948.n(this.prototype, "_initGoogleDFP", [(0, _decorators.on)("didInsertElement")]))();
    willRender() {
      super.willRender(...arguments);
      if (!this.get("showAd")) {
        return;
      }
    }
    cleanup() {
      destroySlot(this.get("divId"));
    }
    static #_14 = (() => dt7948.n(this.prototype, "cleanup", [(0, _decorators.on)("willDestroyElement")]))();
  }, [(0, _component2.classNames)("google-dfp-ad"), (0, _component2.classNameBindings)("adUnitClass")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GoogleDfpAd);
});